/*----------------------------------------------------------------*/
/*  @ Chart C3
/*----------------------------------------------------------------*/

.c3 {
    transition: all 300ms ease;

    svg {
        font: 12px RobotoDraft, Roboto, 'Helvetica Neue', sans-serif;
    }

    path, line {
        fill: none;
        stroke: #000;
    }

    text {
        font-family: $font-family-body;
        user-select: none;
    }

    .c3-legend-item-tile, .c3-xgrid-focus, .c3-ygrid, .c3-event-rect, .c3-bars path {
        shape-rendering: crispEdges;
    }
    /*-- Chart --*/

    /*-- Line --*/
    .c3-chart-line {
        transition: all 300ms ease;
    }
    .c3-line {
        stroke-width: 1px;
    }
    &.stroke-3 {
        .c3-line {
            stroke-width: 3px;
        }
    }
    &.stroke-2 {
        .c3-line {
            stroke-width: 2px;
        }
    }
    /*-- Point --*/
    .c3-circle {
        transition: all 300ms ease;
        stroke: white;
        stroke-width: 2;
        &._expanded_ {
            stroke-width: 3;
            stroke: white;
        }
    }

    .c3-selected-circle {

    }

    .c3-chart-arc {
        path {
            stroke: #fff;
        }

        text {
            fill: #fff;
            font-size: 13px;
        }
    }

    /*-- Axis --*/
    /*-- Grid --*/
    .c3-grid {
        line {
            stroke: rgba(0, 0, 0, .04);
            stroke-width: 1px;
            stroke-dasharray: 0;
        }

        text {
            fill: #aaa;
        }
    }
    .c3-axis {
        .tick {
            stroke-width: 0;
        }
        text {
            fill: rgba(0, 0, 0, .33);
        }
        line, path {
            stroke: rgba(0, 0, 0, .04);
        }
    }
    .c3-xgrid, .c3-ygrid {
        stroke-dasharray: 0 0;
    }

    /*-- Text on Chart --*/
    .c3-text.c3-empty {
        fill: #808080;
        font-size: 2em;
    }

    /*-- Bar --*/
    .c3-bar {
        stroke-width: 0;
        &._expanded_ {
            fill-opacity: 0.75;
        }
    }

    /*-- Focus --*/
    .c3-target {
        &.c3-focused {
            opacity: 1;
            path.c3-line, .c3-target.c3-focused path.c3-step {
                stroke-width: 3px;
                transition: all 300ms ease;

            }
        }
        &.c3-defocused {
            opacity: 0.3 !important;
        }
    }

    /*-- Region --*/
    .c3-region {
        fill: steelblue;
        fill-opacity: 0.1;
    }

    /*-- Brush --*/
    .c3-brush .extent {
        fill-opacity: 0.1;
    }

    /*-- Select - Drag --*/
    /*-- Legend --*/
    .c3-legend-item {
        font-size: 12px;
    }

    .c3-legend-item-hidden {
        opacity: 0.15;
    }

    .c3-legend-background {
        opacity: 0.75;
        fill: white;
        stroke: lightgray;
        stroke-width: 1;
    }

    /*-- Tooltip --*/
    .c3-tooltip-container {
        z-index: 10;
    }

    .c3-tooltip {
        border-collapse: collapse;
        border-spacing: 0;
        background-color: #fff;
        empty-cells: show;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084);
        opacity: 1;
        border-radius: 2px;
        overflow: hidden;
        padding: 4px 0;
        tr {
            border: none;
        }
        th {
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            padding: 8px 12px;
            text-align: left;
            color: rgba(0, 0, 0, 0.54);
        }
        td {
            font-size: 13px;
            padding: 4px 12px !important;
            background-color: #fff;
            border-left: none;
            color:rgba(0,0,0,0.84);

            > span {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 6px;
            }

            &.value {
                font-size: 14px !important;
                text-align: right;
                color:rgba(0,0,0,0.84);
            }
        }
    }

    /*-- Area --*/
    .c3-area {
        stroke-width: 0;
        opacity: 0.2;
    }

    /*-- Arc --*/
    .c3-chart-arcs-title {
        dominant-baseline: middle;
        font-size: 1.3em;
    }

    .c3-chart-arcs {
        .c3-chart-arcs-background {
            fill: #e0e0e0;
            stroke: none;
        }

        .c3-chart-arcs-gauge-unit {
            fill: #000;
            font-size: 16px;
        }

        .c3-chart-arcs-gauge-max {
            fill: #777;
        }

        .c3-chart-arcs-gauge-min {
            fill: #777;
        }
    }

    .c3-chart-arc .c3-gauge-value {
        fill: #000;
    }
}
