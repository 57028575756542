.ms-card {

    .template-1 {

        .media {

            .image {
                width: 100%;
                border-radius: 2px 2px 0 0;
            }
        }
    }
}