.ct-label {
    font-size: 13px;
}

.ct-chart-donut,
.ct-chart-pie {

    .ct-label {
        font-size: 13px;
    }
}