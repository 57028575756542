.ms-card {

    .template-9 {

        .header {

            .avatar {

                .image {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                }
            }
        }

        .media {

            .image {
                width: 100%;
            }
        }
    }
}