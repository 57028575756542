.ms-card {

    .template-4 {

        .info {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.54);
        }

        .media {
            z-index: 1;

            .image {
                width: 100%;
            }
        }
    }
}