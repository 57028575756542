/*----------------------------------------------------------------*/
/*  ms-material-color-picker
/*----------------------------------------------------------------*/
ms-material-color-picker {

    md-menu {

        .md-button {
            background-color: #EEEEEE;
            font-size: 12px;
        }
    }
}

md-menu-content {

    &.ms-material-color-picker-menu-content {
        width: 208px;
        max-width: 208px;
        min-width: 208px;
        padding: 0;

        header {
            height: 48px;
            text-transform: uppercase;

            .md-button {
                display: flex;
                margin: 0;
            }

            > span {
                line-height: 13px;
            }
        }

        .colors {
            position: relative;
            width: 208px;
            height: 256px;
            padding: 8px;

            .color {
                position: relative;
                width: 40px;
                height: 40px;
                margin: 4px;
                border-radius: 2px;
                cursor: pointer;

                .label {
                    padding: 2px;
                    font-size: 10px;
                }

                .icon-check {
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    font-size: 16px;
                    opacity: 0.7;
                }
            }
        }
    }
}